import Vue from 'vue'
import Router from 'vue-router'
import Splash from '@/pages/Splash.vue'
import Top from '@/pages/Top.vue'
import News from '@/pages/News.vue'
import Privacy from '@/pages/Privacy.vue'
import Company from '@/pages/Company.vue'
import Works from '@/pages/Works.vue'
import Recruit from '@/pages/Recruit.vue'
import NewGraduates from '@/pages/NewGraduates.vue'
import Career from '@/pages/Career.vue'
import Data from '@/pages/Data.vue'
import Contact from '@/pages/Contact.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Splash
    },
    {
      path: '/top',
      component: Top
    },
    {
      path: '/news',
      component: News
    },
    {
      path: '/privacy',
      component: Privacy
    },
    {
      path: '/company',
      component: Company
    },
    {
      path: '/works',
      component: Works
    },
    {
      path: '/recruit',
      component: Recruit
    },
    {
      path: '/new-graduates',
      component: NewGraduates
    },
    {
      path: '/career',
      component: Career
    },
    {
      path: '/data',
      component: Data
    },
    {
      path: '/contact',
      component: Contact
    }
  ],
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
        return savePosition;
    }
    return { behavior: 'smooth', x: 0, y: 0 };
}
})