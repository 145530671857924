<template>
  <div>
    <div class="topic-title">{{ title }}</div>
    <hr class="underline1">
    <hr class="underline2">
  </div>
</template>
  
<script>
  export default {
    props: {
      title: {
        type: String
      }
    }
  }
</script>
<style>
.topic-title {
  font-family: "BIZ UDGothic";
  margin: 20px 20px 0px 20px;
  font-weight: bold;
  color: #002060;
}
.underline1 {
  margin-top: 4px;
  height: 5px;
  background-color: #002060;
}
.underline2 {
  height: 1px;
  background-color: #002060;
}
</style>