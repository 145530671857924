<template>
  <div id="contents" class="bk-white pb-20">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'WORKS'" :jpTitle="'開発実績'" />
    <title-image class="mb-32" :key="works" :image="'works-back.png'">
      <template v-slot:title><h2>弊社開発実績の一部をご紹介します。</h2></template>
      <template v-slot:description2>
        <p class="img-description2">
          FIRSTSYSTEMは、Java・WEBアプリケーションの設計～開発を強みとしております。<br>
          さらに近年はRPAのような新しいテクノロジーにも携わりながら、IT活用によるお客様のビジネスの成長を支援しております。<br>
        </p>
      </template>
    </title-image>
    <div class="map-table mb-32">
      <table>
        <tr>
          <th style="width:40%">
            <ul class="map-ul-out">
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">WEB</li>
                  <li class="system-type bk-darkgray">ｱﾌﾟﾘ</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('realestate-support')">不動産購入者のローン審査、契約業務支援</button></li>
                </ul>
              </li>
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">WEB</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('callcenter-support')">コールセンター業務支援システム</button></li>
                </ul>
              </li>
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">C/S</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('bank-support')">銀行業務支援</button></li>
                </ul>
              </li>
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">WEB</li>
                  <li class="system-type bk-darkgray">ｱﾌﾟﾘ</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('cashless-system')">スマートフォン・キャッシュレス決済システム</button></li>
                </ul>
              </li>
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">他</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('pcidss-support')">PCI DSS準拠支援</button></li>
                </ul>
              </li>
            </ul>
          </th>
          <td style="width:10%">
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="year-circle mb-20 bk-black">2020</div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
          </td>
          <th style="position: relative;">
            <div style="font-size: 32px;color: #D0CECE;position: absolute;top: -10px;">Company History</div><br>
            <p class="hist-title">顧客ビジネスの成長をITで支援</p>
            <p class="fs-12 pl-10">
              培った業務ノウハウをベースに進歩するIT技術を積極的に活用することで、<br>
              目まぐるしく変化するビジネス環境や社会要請への適応を支援。
            </p>
          </th>
        </tr>
        <tr>
          <th style="width:40%">
            <fieldset class="map-fieldset">
              <legend class="bk-white map-legend">パッケージ製品＆ソリューション</legend>
              <ul class="map-ul-out">
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">C/S</li>
                    <li class="system-type bk-darkgray">WEB</li>
                    <li class="system-type bk-darkgray">ｱﾌﾟﾘ</li>
                    <li class="system-type bk-darkgray">他</li>
                    <li><button class="inhouse-button bk-darkblue" @click="ScrollWindow('field-support-glasses')">Field&emsp;Support&emsp;Glasses</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">C/S</li>
                    <li class="system-type bk-darkgray">WEB</li>
                    <li class="system-type bk-darkgray">ｱﾌﾟﾘ</li>
                    <li><button class="inhouse-button bk-darkblue" @click="ScrollWindow('disaster-relief-tool')">災害支援ツール</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">C/S</li>
                    <li class="system-type bk-darkgray">WEB</li>
                    <li class="system-type bk-darkgray">他</li>
                    <li><button class="inhouse-button bk-darkblue" @click="ScrollWindow('location-search-system')">位置情報検索・追尾システム</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">WEB</li><li class="system-type bk-darkgray">ｱﾌﾟﾘ</li>
                    <li><button class="inhouse-button bk-darkblue" @click="ScrollWindow('smart-transceiver')">Smart Transceiver</button></li>
                  </ul>
                </li>
              </ul>
            </fieldset>
          </th>
          <td style="width:10%">
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="year-circle mb-20 bk-black">2010</div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
          </td>
          <th>
            <p class="hist-title">WEBサービス事業・価値創造型情報サービス事業へ</p>
            <p class="fs-12 pl-10">
              培った業務ノウハウと技術力をベースに、自社独自のWebサービス事業を展開。<br>
              企業ソリューション、ネットサービスを相乗作用させ、付加価値を生み出す情報サービスの提供を推進。
            </p>
          </th>
        </tr>
        <tr>
          <th style="width:40%">
            <ul class="map-ul-out">
              <li>
                <ul class="map-ul-in">
                  <li class="system-type bk-darkgray">C/S</li>
                  <li class="system-type bk-darkgray">WEB</li>
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('salary-develop')">人事給与システム</button></li>
                </ul>
              </li>
              <li>
                <ul class="map-ul-in">
                  <li><button class="project-button bk-lightblue" @click="ScrollWindow('creditcardfront-develop')">クレジットカード・フロント業務システム</button></li>
                </ul>
              </li>
            </ul>
          </th>
          <td style="width:10%">
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="year-circle mb-20 bk-black">2005</div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
          </td>
          <th>
            <p class="hist-title">金融系システム構築プロジェクトにて</p>
            <p class="fs-12 pl-10">
              業務ノウハウの吸収に向け、金融系システム開発プロジェクトへ経営リソースを集中。<br>
              クレジットカード業務を中心に、業務ノウハウを蓄積。
            </p>
          </th>
        </tr>
        <tr>
          <th style="width:40%">
            <fieldset class="map-fieldset">
              <legend class="bk-white map-legend">機器制御ソリューション</legend>
              <ul class="map-ul-out">
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">C/S</li>
                    <li class="system-type bk-darkgray">他</li>
                    <li><button class="project-button bk-lightblue" @click="ScrollWindow('demosystem-develop')">デモシステム</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">WEB</li>
                    <li><button class="project-button bk-lightblue" @click="ScrollWindow('marketingsite-develop')">マーケティングサイト</button></li>
                  </ul>
                </li>
              </ul>
            </fieldset>
          </th>
          <td style="width:10%">
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="year-circle mb-20 bk-black">2000</div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
          </td>
          <th>
            <p class="hist-title">WEBシステムASP事業へ</p>
            <p class="fs-12 pl-10">ASP事業者のもと、Webサービスとしてのソフトウェア開発、インフラ構築技術を吸収。</p>
          </th>
        </tr>
        <tr>
          <th style="width:40%">
            <fieldset class="map-fieldset">
              <legend class="bk-white map-legend">グループ企業</legend>
              <ul class="map-ul-out">
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">WEB</li>
                    <li><button class="project-button bk-lightblue" @click="ScrollWindow('group-settlement-develop')">連結予決算システム</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">WEB</li>
                    <li><button class="project-button bk-lightblue" @click="ScrollWindow('group-assetmanagement-develop')">資金管理システム</button></li>
                  </ul>
                </li>
                <li>
                  <ul class="map-ul-in">
                    <li class="system-type bk-darkgray">WEB</li>
                    <li><button class="project-button bk-lightblue" @click="ScrollWindow('group-finance-develop')">財務会計システム</button></li>
                  </ul>
                </li>
              </ul>
            </fieldset>
          </th>
          <td style="width:10%">
            <div class="mini-circle mb-20 bk-lightgray"></div>
            <div class="year-circle mb-20 bk-black">1995</div>
            <div class="mini-circle mb-20 bk-lightgray"></div>
          </td>
          <th>
            <p class="hist-title">オープンシステム開発事業体系を構築</p>
            <p class="fs-12 pl-10">
              ＰＣパッケージ開発案件に積極的に参入。Ｃ/Ｓ型システムでオープンシステム開発事業体系を構築。<br>
              グループ企業経営システムのＷｅｂによる構築を一括受託。<br>
              これを機にWeb型システム開発を主力事業として展開。
            </p>
          </th>
        </tr>
        <tr>
          <th style="width:40%"></th>
          <td style="width:10%">
            <div class="year-circle bk-black">1989</div>
          </td>
          <th>
            <p class="hist-title">設立</p>
            <p class="fs-12 pl-10">メインフレーム受託開発を主の事業として創業。お客様の信頼獲得を目標に事業規模を拡大。</p>
          </th>
        </tr>
      </table>
    </div>

    <works-project-table :id="'realestate-support'" :project="'不動産購入者のローン審査、契約業務支援 （2022年～）'" :industry="'金融'"
      :systemForm="['-','●','●','●','-']" :process="['●','●','-','-','-','-']">
      <template v-slot:outline>
        <p>
          投資用不動産購入者のローン審査業務や契約業務のローコード開発に従事<br>
          &emsp;・既存システムに打鍵して行っていた審査業務を自動化<br>
          &emsp;・Excelで行っていた帳票出力や管理業務をシステム化<br>
          &emsp;・他社の電子契約システムとの連携を実現
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows&emsp;／&emsp;DB ： Microsoft Dataverse<br>
          言語 ： 開発ツール ： Microsoft Power Platform(PowerApp、PowerAutomate)
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'callcenter-support'" :project="'コールセンター業務支援システム開発 （2019年～）'" :industry="'金融／クレジットカード'"
      :systemForm="['-','●','-','-','-']" :process="['-','●','●','●','●','●']">
      <template v-slot:outline>
        <p>
          大手クレジットカード会社のコールセンター向けに、顧客対応システムのエンハンス開発作業に従事<br>
          &emsp;・RPAを活用して複数システム（プリペイドカードごと）の画面操作を一本化し、オペレータの負担を低減して効率化
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows<br>
          言語 ： Java&emsp;／&emsp;RPAツール ： Pega
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'bank-support'" :project="'銀行業務支援 （2019年～）'" :industry="'金融／銀行'"
      :systemForm="['●','-','-','-','-']" :process="['-','●','●','●','●','●']">
      <template v-slot:outline>
        <p>
          商品先物取引法の改正に伴う各種業務の効率化に従事<br>
          &emsp;・BIツール活用による大量取引データの分析～可視化、不一致データ特定作業の自動化<br>
          &emsp;・行内記帳取引データと規制当局報告済みデータを全項目突合し、不一致情報を検出
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows&emsp;／&emsp;DB ： SQL Server<br>
          BIツール ： Tableau Desktop、Tableau Prep Builder、SSIS（SQL Server Integration Services）
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'cashless-system'" :project="'スマートフォン・キャッシュレス決済システム開発 （2018年～）'" :industry="'ITサービス'"
      :systemForm="['-','●','-','●','-']" :process="['●','●','●','●','●','-']">
      <template v-slot:outline>
        <p>
          バーコード／QRコードによる新世代スマートフォン決済ツールのエンハンス開発作業に従事<br>
          &emsp;・複数のキャッシュレス決済ブランドとの連動を行い、総合決済サービスとして金融業界全体に影響を与える
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Linux(AWS)&emsp;／&emsp;DB ： PostgreSQL<br>
          言語 ： Java（サーバ側・Android）、Swift（iOS）、Vue
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'pcidss-support'" :project="'PCI DSS準拠支援 （2017年～）'" :industry="'金融／クレジットカード'"
      :systemForm="['-','-','-','-','●']">
      <template v-slot:outline>
        <p>
          PCI DSS要求事項を充足するための支援システムの構築～運用作業に従事<br>
          &emsp;・将来的な準拠システム拡大を見据えて、要求事項の実装を担い全体で共用されるシステムを新たに構築<br>
          <span class="fs-9 f-left">&emsp;&emsp;※PCI DSS：クレジットカード情報および取引情報の保護を目的とするグローバルセキュリティ基準</span>
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Linux、Windows<br>
          ［利用プロダクト］<br>
          &emsp;OSアカウント管理 ： パワーセキュリティ、PowerBroker<br>
          &emsp;サーバアクセス管理 ： Citrix XenApp、Citrix NetScaler、SafeNet Authentication Service<br>
          &emsp;ログ管理 ： Splunk Enterprise<br>
          &emsp;脆弱性情報管理 ： IBM QRadar Vulnerability Manager
        </p>
      </template>
      <template v-slot:processElement>
        <table>
          <tr class="bk-darkgray"><th colspan="6">工程</th></tr>
          <tr class="bk-darkgray"><th>提案/見積</th><th>RD</th><th>UI</th><th>SS/PG/PT</th><th>IT/ST/OT</th><th>運用</th></tr>
          <tr class="bk-white"><td>‐</td><td>‐</td><td>●</td><td>●<span class="fs-6">(※1)</span></td><td>●</td><td>●</td></tr>
        </table>
        <p class="fs-9 f-left">（※1）…PG開発作業ではなく、「ミドルウェア類のパラメータ設計やインストール～設定作業」となります。</p>
      </template>
    </works-project-table>
    <works-project-table :id="'salary-develop'" :project="'人事給与システム開発～運用 （2008年～）'" :industry="'ITサービス'"
      :systemForm="['●','●','●','-','-']" :process="['-','-','●','●','●','●']">
      <template v-slot:outline>
        <p>
          人事給与システムの設計開発～運用作業に従事<br>
          &emsp;・マルチユーザ向けのクラウドサービスとして、人事給与に関する業務全般を総合的にサポート<br>
          &emsp;・給与計算～給与明細照会、年末調整業務、人事諸手続きの申請ワークフロー
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows、Linux&emsp;／&emsp;WEB ： Apache、Tomcat&emsp;／&emsp;DB ： Oracle<br>
          言語 ： Java、PL/SQL
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'creditcardfront-develop'" :project="'クレジットカード・フロント業務システム開発'" :industry="'金融／クレジットカード'">
      <template v-slot:outline>
        <p>
          クレジットカード関連のフロント業務を支援するシステムの設計開発作業に従事<br>
          &emsp;・入会審査自動化およびシステム連携効率化によりスピード発行を実現し、顧客利便性を向上<br>
          &emsp;・DWHを活用して業務ノウハウやルールをデータ化し、業務効率を向上
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Solaris、AIX&emsp;／&emsp;WEB ： WebLogic&emsp;／&emsp;DB ： Oracle<br>
          言語 ： Java、PL/SQL
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'demosystem-develop'" :project="'機器制御ソリューション・デモシステム開発 （2003～2004年）'" :industry="'製造'"
      :systemForm="['●','-','-','-','●']" :process="['-','-','●','●','●','-']">
      <template v-slot:outline>
        <p>
          オープンアーキテクチャによる自律分散型・制御ネットワークに関する、プレゼン用デモシステムの設計開発作業に従事<br>
          &emsp;・ビル管理（照明、空調、セキュリティ）／工場設備管理（ボイラー、ポンプ、配電盤）／店舗管理（広域モニタリング、機器故障検知）
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows<br>
          言語 ： 専用SDK、Visual Basic
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'marketingsite-develop'" :project="'機器制御ソリューション・マーケティングサイト開発～運用 （2002～2006年）'" :industry="'製造'"
      :systemForm="['-','●','-','-','-']" :process="['-','-','●','●','●','●']">
      <template v-slot:outline>
        <p>
          機器制御ソリューションにまつわる総合WEBサイトの設計開発～運用作業に従事<br>
          &emsp;・製品情報や導入事例の紹介、各種セミナーやベンダ認定トレーニングの予約受付、対応製品登録～掲載<br>
          &emsp;・入手した顧客情報等のデータを営業系システムに連携し、事業拡大に寄与
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Linux&emsp;／&emsp;WEB： Apache、Tomcat&emsp;／&emsp;DB： Oracle<br>
          言語： Java
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'group-settlement-develop'" :project="'グループ企業・連結予決算システム開発～運用 （1999～2002年）'" :industry="'製造'"
      :systemForm="['-','●','-','-','-']" :process="['-','-','●','●','●','●']">
      <template v-slot:outline>
        <p>
          グループ企業の連結会計業務を支援するシステムの設計開発～運用作業に従事<br>
          &emsp;・WEBを活用して情報開示～データ収集を効率化し、各社決算・連結決算までの期間を大幅に短縮<br>
          &emsp;・収集したデータを経営情報として蓄積し、多角的な経営分析を実現
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows&emsp;／&emsp;WEB ： OAS（Oracle Application Server）&emsp;／&emsp;DB ： Oracle<br>
          言語 ： PL/SQL（Web）、Visual Basic（Excel帳票）
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'group-assetmanagement-develop'" :project="'グループ企業・資金管理システム開発～運用 （1998年～）'" :industry="'製造'"
      :systemForm="['-','●','-','-','-']" :process="['-','-','●','●','●','●']">
      <template v-slot:outline>
        <p>
          グループ企業における全ての金銭取引を決済するシステムの設計開発～運用作業に従事<br>
          &emsp;・資金調達一元化、システム内仮想口座による各種決済を通じて、銀行手数料や支払金利等の経費を大幅に削減<br>
          &emsp;・支払/入金管理、ファームバンキング連携、日次決算～残高連動による自動融資/預り、仮想口座の利息計算、外貨取引
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows、Linux&emsp;／&emsp;WEB ： Oracle HTTP Server&emsp;／&emsp;DB ： Oracle<br>
          言語 ： PL/SQL（Web）、VB.NET（Excel帳票）
        </p>
      </template>
    </works-project-table>
    <works-project-table :id="'group-finance-develop'" :project="'グループ企業・財務会計システム開発～外販支援 （1998～2002年）'" :industry="'製造'"
      :systemForm="['-','●','-','-','-']" :process="['-','-','●','●','●','●']">
      <template v-slot:outline>
        <p>
          財務会計システムの設計開発、および外販向けの製品化作業に従事<br>
          &emsp;・WEBを活用して端末ソフトウェアの導入更新作業を不要とし、運用コストを大幅に削減<br>
          &emsp;・グループ利用に備え元帳の多重化および階層化、柔軟なタグ付け機能により経営分析を支援
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： Windows&emsp;／&emsp;WEB ： IIS&emsp;／&emsp;DB ： Oracle<br>
          言語 ： ASP、VBScript、Visual Basic（ActiveX、Excel帳票）
        </p>
      </template>
    </works-project-table>
    <works-inhouse-table :id="'field-support-glasses'" :inhouse="'Field Support Glasses －作業現場向け・スマートグラス－ （2016年）'"
      :classification="'パッケージ製品'" :image="'FieldSupportGlasses-icon.jpg'" :systemForm="['●','●','-','●','●']">
      <template v-slot:feature>
        <p>
          スマートグラスで、現場作業をサポート！<br>
          &emsp;・作業映像のリアルタイム送信<br>
          &emsp;・双方向の音声通話<br>
          &emsp;・スマートグラスで、作業手順書等のPDFファイル閲覧
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS（管理端末側） ： Windows 7、Windows 8<br>
          ユーザデバイス ： EPSON MOVERIO<br>
          言語 ： Java
        </p>
      </template>
    </works-inhouse-table>
    <works-inhouse-table :id="'smart-transceiver'" :inhouse="'Smart Transceiver －Wi-Fi無料通話システム－ （2012年）'"
      :classification="'パッケージ製品'" :image="'SmartTransceiver-icon.jpg'" :systemForm="['-','●','-','●','-']">
      <template v-slot:feature>
        <p>
          スマート端末（スマートフォン、タブレット端末）をトランシーバーとして活用し、コミュニケーションを支援！<br>
          &emsp;・グループ内やグループ間での音声通話<br>
          &emsp;・専用トランシーバー不要<br>
          &emsp;・安価で容易なシステム導入が可能<br>
          &emsp;・多様なネットワークが利用可能<br>
          &emsp;・利用状況のモニタリング <br>
          <span class="fs-12 pl-10">&emsp;※（公財）東京都中小企業振興公社・ニューマーケット開拓支援事業認定製品（2015年5月1日）</span>
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： ［サーバ側］ Windows Server 2012、Windows 7&emsp;&emsp;［クライアント側］ Android 2.1～4<br>
          DB ： H2<br>
          言語 ： Java
        </p>
      </template>
    </works-inhouse-table>
    <works-inhouse-table :id="'disaster-relief-tool'" :inhouse="'災害支援ツール （2016年）'"
      :classification="'ソリューション'" :image="'DisasterRelief-icon.jpg'" :systemForm="['●','●','-','●','-']">
      <template v-slot:feature>
        <p>
          スマートフォンが、災害支援ツールに早変わり！<br>
          &emsp;・災害発生時の初動対応支援（端末アプリの遠隔一斉起動、位置情報収集）<br>
          &emsp;・被害状況/救護状況/避難所状況等のリアルタイム映像送信～本部でのマルチモニタリング<br>
          &emsp;・音声通話（Smart Transceiver） <br>
          <span class="fs-12 pl-10">&emsp;※（公社）危機管理協会シーマ・CMA認定製品（2016年8月31日）</span>
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： ［管理端末側］&emsp;Windows 7、Windows 8&emsp;&emsp;［クライアント側］&emsp;Android 2.1～4<br>
          DB ： H2<br>
          言語 ： Java
        </p>
      </template>
    </works-inhouse-table>
    <works-inhouse-table :id="'location-search-system'" :inhouse="'位置情報検索・追尾システム （2013年）'"
      :classification="'ソリューション'" :systemForm="['●','●','-','-','●']">
      <template v-slot:feature>
        <p>
          GPSによりモノの「位置」と「動き」をトラッキング！<br>
          &emsp;・遠隔地から任意のタイミングで、位置情報（緯度・経度）が取得可能（GPS端末側の操作は不要）<br>
          &emsp;・必要な時、必要な時間だけ情報収集が行えるため、無駄な通信費が発生しない<br>
          &emsp;・適用例 ⇒ カーセキュリティ（盗難検知～追尾）、車両運行管理（配車支援、運行経路捕捉、運行状況モニタリング）
        </p>
      </template>
      <template v-slot:environment>
        <p>
          OS ： ［サーバ側］ CentOS 5.1&emsp;&emsp;［クライアント側］ Windows XP、Windows 7<br>
          DB ： MySQL<br>
          言語 ： Java、VB.NET
        </p>
      </template>
    </works-inhouse-table>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import TitleImage from '@/components/title/TitleImage.vue'
import WorksProjectTable from '@/components/works/ProjectTable.vue'
import WorksInhouseTable from '@/components/works/InhouseTable.vue'

export default {
  components: {
    Breadcrumb,
    PageTitle,
    TitleImage,
    WorksProjectTable,
    WorksInhouseTable
  },
  methods: {
    ScrollWindow(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    }
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'WORKS' }
      ]
    }
  }
}
</script>
<style>
.map-table { padding: 20px; text-align: left; }
.map-table th { font-size: 15px; font-weight: normal; border: none; }
.map-table td { font-size: 15px; border: none; }

.map-ul-out { list-style: none; flex-direction: row; justify-content: space-around; text-align: right; }
.map-ul-in { display: inline-flex; list-style: none; text-align: right; }
.map-fieldset { border: 1px solid #000000; padding: 16px; border-radius: 24px 0px 24px 0px; position: relative; }
.map-legend { position: absolute; top: -12px; right: 20px; font-size: 13px; font-weight: bold; padding: 0px 8px; }
.system-type { width: 32px; font-size: 14px; color: #ffffff; border-radius: 8px; margin: 2px; padding: 8px 3px; text-align: center; }
.project-button {
  cursor: pointer;
  width: 350px;
  height: 36px;
  color: #ffffff;
  border-radius: 8px;
  margin: 2px;
  text-align: center;
  border-top: 5px solid #64d4ff;
  border-right: 5px solid #285d94;
  border-left: 5px solid #285d94;
  border-bottom: 3px solid #285d94;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}
.project-button:active {
  border-color: #3399FF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.project-button button { width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0); }
.inhouse-button {
  cursor: pointer;
  width: 270px;
  height: 36px;
  color: #ffffff;
  border-radius: 8px;
  margin: 2px; 
  text-align: center;
  border-top: 5px solid #305797;
  border-right: 5px solid #1b305a;
  border-left: 5px solid #1b305a;
  border-bottom: 3px solid #1b305a;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
}
.inhouse-button:active {
  border-color: #002060;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.inhouse-button button { width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0); }
.mini-circle { width: 24px; height: 24px; border-radius: 50%; margin-right: auto; margin-left: auto; }
.year-circle { 
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px;
  font-weight: bold;
  line-height: 470%;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.4);
}
.hist-title { border-bottom: solid #000000 1px; font-weight: bold; }
</style>