<template>
  <div class="mb-20 bk-pastelblue">
    <div :id="id" class="works-table">
      <table>
        <tr><th class="bk-darkgray">案件</th><td class="f-bold">{{ project }}</td></tr>
        <tr><th class="bk-darkgray">業種</th><td class="f-bold">{{ industry }}</td></tr>
      </table>
      <hr>
      <table>
        <tr><th class="bk-darkgray">概略</th><td><slot name="outline" /></td></tr>
        <tr><th class="bk-darkgray">環境</th><td><slot name="environment" /></td></tr>
      </table>
    </div>
    <ul  class="system-ul">
      <li v-if="systemForm" class="system-table">
        <table>
          <tr class="bk-darkgray"><th colspan="5">システム形態</th></tr>
          <tr class="bk-darkgray"><th>C/S</th><th>WEB（PC)</th><th>WEB（モバイル）</th><th>モバイルアプリ</th><th>その他</th></tr>
          <tr class="bk-white"><td v-for="n in systemForm" :key="n">{{ n }}</td></tr>
        </table>
      </li>
      <li v-if="process" class="system-table">
        <table>
          <tr class="bk-darkgray"><th colspan="6">工程</th></tr>
          <tr class="bk-darkgray"><th>提案/見積</th><th>RD</th><th>UI</th><th>SS/PG/PT</th><th>IT/ST/OT</th><th>運用</th></tr>
          <tr class="bk-white"><td v-for="n in process" :key="n">{{ n }}</td></tr>
        </table>
      </li>
      <li v-else class="system-table"><slot name="processElement" /></li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      require: true
    },
    project: {
      type: String,
      require: true
    },
    industry: {
      type: String,
      require: true
    },
    outline: {
      type: String,
      require: true
    },
    systemForm: {
      type: Array[5]
    },
    process: {
      type: Array[6]
    }
  }
}
</script>
<style>
.works-table { padding: 20px; text-align: left; }
.works-table table { border-collapse: separate; border-spacing: 8px 12px; }
.works-table th { width: 5%; font-size: 15px; font-weight: normal; border: none; color: #FFFFFF; text-align: center; padding: 4px; }
.works-table td { font-size: 15px; border: none; padding-left: 20px; white-space: pre-wrap; }
.system-ul { display: flex; list-style: none; flex-direction: row; justify-content: space-around; padding-bottom: 20px; }
.system-table { padding: 0px 20px; text-align: center; }
.system-table th { font-size: 12px; font-weight: normal; color: #FFFFFF; border: 1px solid #FFFFFF; text-align: center; padding: 4px; }
.system-table td { font-size: 18px; padding: 4px; margin: 8px; border: 1px solid #F2F2F2; }
</style>