<template>
  <div id="contents" class="bk-white">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'RECRUIT'" :jpTitle="'採用メッセージ'" />
    <title-image :key="recruit" :image="'recruit-back.png'">
      <template v-slot:title><h3>エンジニアの存在意義を、真剣に考えている会社です。</h3></template>
      <template v-slot:description2>
        <p class="img-description2">
          ただやみくもにIT技術を習得したからといって、それは一流のITエンジニアではない。<br>
          まずは業務ノウハウの習得から始めましょう。<br><br>
          お客様の業務を知ることで、お客さまに満足していただける提案が可能になり、質の高いITエンジニアだと言える。<br><br>
          そう、IT技術はあくまでも手段。<br>
          その先にある”お客さまの満足”こそが目的である…私たちの根っこに流れる考え方です。<br><br>
          Webという分野に絞って事業を展開することで、専門性を高めてきたFIRSTSYSTEM。<br><br>
          大手企業をはじめとする一次請け案件が半数を占めており、<br>
          様々な過程を学びながら経験できるため、<br>
          「下請けで終わりたくない」という方にとってのキャリア形成も実現可能です。<br><br>
          今後は、会社としてのフラッグシップとなる事業を作るべく、<br>
          新しい領域にもチャレンジして幅を広げ、会社全体を盛り上げていきたいと考えています。<br><br>
          一人ひとりの強みや希望に合わせて、力を１２０％発揮できる環境をご用意します。<br><br>
          少人数の会社ですが、全員が同じベクトルを持ち、今後の成長を加速させていくフェーズに入っています。<br>
          「こんなITエンジニアになりたい」という夢を当社で実現しませんか？<br><br>
          お客さまにとって、本当に役立つシステムを提供できるITエンジニアへの道。<br>
          FIRSTSYSTEMでの経験が、道中のスピードを加速させることは間違いありません。
        </p>
      </template>
    </title-image>
    <sub-title :title="'PERSONALITY'" :jpTitle="'求める人物像'" />
    <div class="topic-description bk-white">
      小さな当たり前の積み上げが何より大事！<br>
      <p class="fs-24">当たり前のことを当たり前に継続し続けられる人</p><br>
      ◆報告・連絡・相談が、スマートにできる<br>
      ◆コツコツと努力を積み重ねることができる<br>
      ◆常に向上心を持ち、新しいスキルや知識の習得ができる<br>
      ◆どんな小さなことでもいいので、新しいことにチャレンジすることができる<br>
      ◆自ら考え行動することができる<br>
      ◆何事も最後まで、やり遂げることができる
    </div>
    <div class="topic-description bk-gray">
      <p class="f-bold">【採用担当者からのメッセージ】</p><br>
      &emsp;「継続は力なり」という言葉をご存じでしょうか？&emsp;<br>
      &emsp;私は、この言葉を胸に日々業務を行っています。<br>
      &emsp;多くの方々とビジネスをする中で、”デキル人”の共通点は同じと感じております。<br>
      &emsp;それは&emsp;”継続して努力し続けている”&emsp;という点です。<br>
      &emsp;弊社は、難易度の高いことを達成できる人材は求めておらず、小さな当たり前の積み重ねを継続し続けれらる人を求めています。
    </div>
    <div> 
      <div class="mt-32 p-8 bk-lightblue" style="position: relative;">
        <div>
          <p class="topic">NEW GRADUATES</p>
          <p class="sub-topic">新卒採用</p>
        </div>
        <!--<div align="center" style="position:absolute; left:980px; top:25px;">
          <a href="https://job.rikunabi.com/2024/company/r811552085/" target="new">
          <img src="https://job.rikunabi.com/2024/static/common/contents/logos/rikunabi/image/rn_logo_b.gif" width="200" height="40" border="0">
          </a><br>
        </div>-->
      </div>
    </div>
    <div class="topic-description bk-white" style="clear: both;">
    文系・理系不問！<br><br>
    当社ＩＴエンジニアの約半数が文系出身者で未経験からのスタート。<br>
    新入社員研修を経て、お客様から信頼されるITエンジニアに成長しています。<br><br>

    ＩＴ技術に関する知識や能力は一切必要ありません！<br>
    必要な知識は入社してから身につけることができるので、<br>
    安心してＦＩＲＳＴＳＹＳＴＥＭの選考に進んでいただければと思います。<br><br>

    現時点での知識や能力よりも、新しいことを積極的に学ぼうとする意欲こそが重要です。<br><br>

    お気軽に、会社説明会にご参加ください。<br>
    FIRSTSYSTEMのこと、IT業界のこと、仕事内容など、詳しく説明させていただきます。<br><br>

    会社説明会エントリーはコチラ▼<br><br>

    <div align="left">
      <a href="https://job.rikunabi.com/2025/company/r811552085/" target="new">
        <img src="https://job.rikunabi.com/2025/static/common/contents/logos/rikunabi/image/rn_logo_s.gif" width="200" height="40" border="0">
      </a><br><br>
      <a href="https://job.rikunabi.com/2025/company/r811552085/" target="new"> リクナビの弊社画面へ </a>
    </div> 

      <view-more :path="'/new-graduates'" />
    </div>
    <sub-title :title="'CAREER'" :jpTitle="'キャリア採用'" />
    <div class="topic-description bk-white">
      創業35年を超えたFIRSTSYTEMには、貴方の知識・技術力を思う存分発揮できる環境があります。<br>
      FIRSTSYSTEMだからこそ実現可能な納得できる”仕事”＆”評価”で満足できるエンジニア人生にしていきましょう！<br>
      <view-more :path="'/career'" />
    </div>
    <sub-title :title="'DATA'" :jpTitle="'データで見る！FIRSTSYSTEM'" />
    <div class="topic-description bk-white">
      FIRSTSYTEMのあれこれをデータで紹介します。<br>
      <br>
      <view-more :path="'/data'" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import SubTitle from '@/components/title/SubTitle.vue'
import TitleImage from '@/components/title/TitleImage.vue'
import ViewMore from '@/components/common/ViewMore.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    SubTitle,
    TitleImage,
    ViewMore
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'RECRUIT' }
      ]
    }
  }
}
</script>
<style>
.topic-description {
  padding: 20px;
  font-size: 16px;
  color: #000000;
}
</style>
