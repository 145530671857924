<template>
  <div id="contents" class="bk-white pb-32">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'PRIVACY POLICY'" :jpTitle="'個人情報保護方針'" />
    <div class="privacy-topic-description">
      ファーストシステム株式会社（以下「当社」という。）は、新しい情報サービスの創造と豊かな情報化社会の発展の一翼を担うべく、幅広い情報サービスの提供を行っています。当社は、情報サービス企業として個人情報を保護することは企業としての社会的責任と考え、個人情報を適切に保護するために、日本工業規格（JIS&emsp;Q15001：2017）に適合する個人情報保護マネジメントシステムを構築すると共に、次に掲げる行動指針を定め、これを「個人情報保護方針」とします。当社の役員及び従業員はこの方針に従い、適切に個人情報を保護いたします。<br/><br/><br/>
      <ol class="privacy-topic-description">
        <li>個人情報に関する法令等の遵守</li>
        役員及び従業員は、個人情報の取扱いに関する法令、国が定める指針その他規範を遵守します。<br/><br/>
        <li>個人情報の取得、利用、提供</li>
        当社は、個人情報の利用目的を事業活動に必要な範囲で明確に定めるとともに、その利用目的の範囲内において、個人情報の適切な取得・利用及び提供を行い、利用目的の達成に必要な範囲を超えた個人情報の取扱い（目的外利用）は行いません。また、そのための措置を講じます。<br/><br/>
        <li>個人情報の安全対策</li>
        当社は、個人情報の漏えい、滅失又はき損を予防するため、合理的な安全対策を講じるとともに、万一発生した際は、速やかに是正処置を講じます。<br/><br/>
        <li>苦情及び相談への対応</li>
        当社は、個人情報の取扱いに関する苦情及び相談に対し、誠実かつ迅速に対応いたします。<br/><br/>
        <li>個人情報保護への継続的改善</li>
        当社は、個人情報保護管理者及び個人情報保護監査責任者を選任し、個人情報保護マネジメントシステムの実施及び運用の管理並びに監査を行います。<br/>
        また、これらの活動を通じて個人情報保護マネジメントシステムを継続的に見直すとともに、その改善に努めます。<br/><br/>
      </ol>
      【個人情報保護方針についての問い合わせ先】<br/>
      &emsp;ファーストシステム株式会社&emsp;“個人情報お問合せ窓口”<br/>
      &emsp;〒111-0053&emsp;東京都台東区浅草橋5-2-14&emsp;浅草橋ハイツ3階<br/>
      &emsp;TEL：03-5809-2214&emsp;※お問合せ受付時間：平日10:00～17:00<br/>
    </div>
    <div class="privacy-topic-description" style="text-align: right">
      制定：2005年4月1日<br/>
      改訂：2023年9月1日<br/><br/>

      ファーストシステム株式会社<br/>
      代表取締役&emsp;西村&emsp;健
    </div>
    <div class="mt-32 p-8 bk-darkblue">
      <p class="privacy-title">個人情報の取扱いについて</p>
    </div>
    <div class="privacy-topic-description">
      事業者の名称：ファーストシステム株式会社<br/>
      事業者の住所：〒111-0053&emsp;東京都台東区浅草橋5-2-14&emsp;浅草橋ハイツ3階<br/>
      代表者の氏名：代表取締役&emsp;西村&emsp;健<br/>
      個人情報保護管理者：情報システム部&emsp;部長<br/>
      ※ 連絡先は、下記の“個人情報お問合せ窓口“となります。
    </div>
    
    <topic-title class="fs-18" :title="'保有個人データの利用目的'" />
    <div class="privacy-topic-description">
      当社は、事業活動において、以下の利用目的の達成に必要な範囲内において個人情報を取得し利用します。<br/>
      特定した利用目的の達成範囲を超えて個人情報を取扱う必要が生じた場合、必ずご本人の同意を得た上で取扱いをいたします。<br/><br/>

      【当社の情報処理サービス事業に関連する個人情報】<br/>
      &emsp;・ 商談及び業務上の諸連絡<br/>
      &emsp;・ 契約の締結及び契約の履行<br/>
      &emsp;・ 受発注に関する業務<br/>
      &emsp;・ 取引先管理及び慶弔・挨拶等に関する業務<br/>
      &emsp;・ 当社が取り扱う商品・サービスに関するご案内<br/>
      &emsp;・ 当社へのお問合せ、資料請求及びその他ご依頼等への対応<br/>
      &emsp;・ 「番号利用法」に基づく個人番号関係事務（報酬料金支払先様）<br/>
      【採用応募者に関する個人情報】<br/>
      &emsp;・ 採用情報等の提供・連絡<br/>
      &emsp;・ 採用選考に関する業務<br/>
      【従業者（家族を含む）、退職者に関する個人情報】<br/>
      &emsp;・ 雇用に関する業務（入社・退職手続き等）<br/>
      &emsp;・ 人事管理に関する業務（人事考課、配属、異動、報酬、教育・研修等）<br/>
      &emsp;・ 就業管理に関する業務（勤務状況、労働時間、休暇、休業、保健医療情報等）<br/>
      &emsp;・ 賃金計算に関する業務（給与・賞与、退職金等）<br/>
      &emsp;・ 業務管理に関する業務（業務実施状況の把握、業務に適した技術者の選定及び営業活動等）<br/>
      &emsp;・ 健康管理に関する業務<br/>
      &emsp;・ 税務、社会保険に関する業務<br/>
      &emsp;・ 経費精算に関する業務<br/>
      &emsp;・ 福利厚生に関する業務<br/>
      &emsp;・ 業務連絡及び緊急連絡等<br/>
      &emsp;・ 会社広報に関する業務（当社ＨＰや各種媒体に従業者紹介記事及び写真等を掲載）<br/>
      &emsp;・ 「番号利用法」に基づく個人番号関係事務<br/>
      【個人情報マネジメントシステムに関する個人情報】<br/>
      &emsp;・ 個人情報保護マネジメントシステムの運用
    </div>

    <topic-title class="fs-18" :title="'第三者提供について'" />
    <div class="privacy-topic-description">
      当社は、以下のいずれかに該当する場合を除き、取得した個人情報及び要配慮個人情報を、あらかじめ本人の同意を得ることなく、第三者に提供しません。<br/><br/>

      ［個人情報］
      <ol class="privacy-topic-description ml-20">
        <li>法令等に基づく場合</li>
        <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
        <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
        <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき</li>
      </ol>
      ［要配慮個人情報］
      <ol class="privacy-topic-description ml-20">
        <li>法令等に基づく場合</li>
        <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
        <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
        <li>国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき</li>
        <li>個人情報取扱事業者が学術研究機関等である場合であって、個人データの提供が学術研究の成果の公表又は教授のためやむを得ないとき（個人の権利利益を不当に侵害するおそれがある場合を除く。）</li>
        <li>個人情報取扱事業者が学術研究機関等である場合であって、個人データを学術研究目的で提供する必要があるとき（個人データを提供する目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）（個人情報取扱事業者と第三者が共同して学術研究を行う場合に限る。）</li>
        <li>第三者が学術研究機関等である場合であって、第三者が個人データを学術研究目的で取り扱う必要があるとき（個人データを取り扱う目的の一部が学術研究目的である場合を含み、個人の権利利益を不当に侵害するおそれがある場合を除く。）</li>
      </ol>
    </div>

    <topic-title class="fs-18" :title="'委託について'" />
    <div class="privacy-topic-description">
      当社は、利用目的の達成に必要な範囲において、業務の一部を外部に委託することがあります。この場合、個人情報を適切に取り扱っていると認められる委託先を選定し、個人情報の取扱に関する契約を締結するとともに適切な監督を行います。
    </div>

    <topic-title class="fs-18" :title="'提供の任意性'" />
    <div class="privacy-topic-description">
      当社に個人情報を提供されるかどうかは任意によるものですが、個人情報を提供いただけない場合、明示しました利用目的を達成できない場合があります。
    </div>

    <topic-title class="fs-18" :title="'保有個人データの開示等について'" />
    <div class="privacy-topic-description">
      当社が保有する個人情報及び第三者提供記録に関して、ご本人又は代理人の方からの個人情報に関する利用目的の通知、開示、訂正・追加又は削除、利用の停止、消去及び第三者提供の停止（以下、「開示等」という。）の請求については<a @click="ScrollWindow('disclosureRequest')" class="privacy-download">「開示等の請求手続について」</a>をご参照ください。請求者がご本人であることを確認の上、対応いたします。ただし、本人又は第三者の生命・身体・財産その他の権利利益を害するおそれがある場合、当社の業務の適正な実施に著しい支障を及ぼすおそれがあると判断した場合、法令等に違反することになる場合は、その全部又は一部のご請求をお断りすることがあります。
    </div>

    <topic-title class="fs-18" :title="'保有個人データの安全管理のために講じた措置'" />
    <div class="privacy-topic-description">
      当社における個人データ等の安全管理措置に関する主な内容は以下のとおりです。<br/><br/>

      <ol class="privacy-topic-description">
        <li>基本方針の策定</li>
        個人データの適正な取扱いの確保のため、関連法令・ガイドライン等の遵守等についての基本方針を策定しています。
        <li>組織的安全管理措置</li>
        個人データの取扱いに関する責任者を設置するとともに、個人情報保護法や社内規定に違反している事実又は兆候を把握した場合の報告連絡体制を整備しています。
        <li>人的安全管理措置</li>
        従業者（役員等を含む。）に対し個人情報保護の重要性を理解させ、適切な運用を図るため、継続的かつ定期的に教育を行うほか、定期的に社内の個人情報管理が適切に実施されているかどうかの監査を行っています。
        <li>物理的安全管理措置</li>
        個人データの適切な保護を図るため、法令に基づき、個人データを取り扱う区域、機器、媒体について、適切な物理的安全管理措置を講じており、詳細は「安全対策管理規程」等の規定を定めて対応しています。
        <li>技術的安全管理措置</li>
        個人データの適切な保護を図るため、法令に基づき、個人データへのアクセス制御、不正アクセスの防止、情報漏えい等の防止等について、適切な技術的安全管理措置を講じており、詳細は「安全対策管理規程」等の規定を定めて対応しています。
        <li>外的環境の把握</li>
        外国において個人データを取り扱う場合には、当該外国における個人情報の保護に関する制度を把握した上で安全管理措置を実施します。
      </ol>
    </div>

    <topic-title class="fs-18" :title="'保有個人データの取扱いに関する苦情の申出先について'" />
    <div class="privacy-topic-description">
      保有個人データの取扱い及び個人情報保護マネジメントシステムに関する苦情・相談については、下記“個人情報お問合せ窓口”までお申し出ください。<br/><br/>
      
      ファーストシステム株式会社&emsp;“個人情報お問合せ窓口“<br/>
      住所：〒111-0053&emsp;東京都台東区浅草橋5-2-14&emsp;浅草橋ハイツ3階<br/>
      電話：03-5809-2214&emsp;※お問合せ受付時間：平日10:00～17:00
    </div>

    <topic-title class="fs-18" :title="'認定個人情報保護団体について'" />
    <div class="privacy-topic-description">
      【認定個人情報保護団体の名称】<br/>
      &emsp;一般財団法人日本情報経済社会推進協会（略称：JIPDEC）<br/><br/>

      【苦情解決の申出先】<br/>
      &emsp;窓口：プライバシーマーク推進センター&emsp;個人情報保護苦情相談室<br/>
      &emsp;住所：〒106-0032&emsp;東京都港区六本木1-9-9&emsp;六本木ファーストビル<br/>
      &emsp;電話：0120-700-779&emsp;<br/>
    </div>
    
    <div class="mt-32 p-8 bk-darkblue" id="disclosureRequest">
      <p class="privacy-title">開示等の請求手続について</p>
    </div>

    <topic-title class="fs-18" :title="'開示等の請求先'" />
    <div class="privacy-topic-description">
      ファーストシステム株式会社&emsp;個人情報お問合せ窓口<br/>
      住所：〒111-0053&emsp;東京都台東区浅草橋5-2-14&emsp;浅草橋ハイツ3階<br/>
      電話：03-5809-2214&emsp;※お問合せ受付時間：平日10:00～17:00<br/>
      ※ 郵送以外での開示等の請求は、お断りさせていただいておりますので、あらかじめご了承ください。<br/>
      &emsp; なお、手続き等に関してご不明な点がある場合は、“個人情報お問合せ窓口”までお問合せください。
    </div>

    <topic-title class="fs-18" :title="'開示等の請求に際して提出すべき書類（様式）ならびに開示等の請求方法'" />
    <div class="privacy-topic-description">
      <ol class="privacy-topic-description">
        <li>開示等の請求を行う場合は、当社所定の「個人情報開示等請求書」に必要事項をご記入の上、下記書類を同封し、発送記録が取れる方法（書留郵便等）にて、当社“個人情報お問合せ窓口”までご郵送ください。</li>
        ※ 同封する書類に以下の項目が明示されている場合は、黒塗り等のマスキング処理をしてください。<br/>
        &emsp;・ 本籍地<br/>
        &emsp;・ 個人番号<br/>
        &emsp;・ 健康保険の被保険者証の保険者番号、被保険者等記号・番号、QRコード<br/>
        ① <a href="/kojinjouhoukaiji_seikyuusyo.pdf" download="個人情報開示等請求書.pdf" class="privacy-download">個人情報開示等請求書</a>&emsp;&emsp;※ＰＤＦファイルをダウンロードし、印刷してご記入ください。<br/>
        ② ご本人確認のための書類（氏名及び現住所が記載されているもの）※a)～c)のいずれかの写し<br/>
        &emsp;a） 運転免許証<br/>
        &emsp;b） 健康保険の被保険者証<br/>
        &emsp;c） 住民票（開示等の請求をする日前30日以内に作成されたもの）<br/>
        ③ 法定代理人（未成年者又は成年被後見人）の場合は、その法定代理人に関する以下の書類<br/>
        &emsp;・ 戸籍謄本又は成年後見登記事項証明書など、法定代理権があることを確認できる書類<br/>
        &emsp;・ 法定代理人本人であることを確認するための書類&emsp;※上記、②a)～c)のいずれかの写し<br/>
        ④ 本人からの委任による代理人の場合は、代理権があることを確認できる以下の書類<br/>
        &emsp;・ <a href="/itakujou.pdf" download="委任状.pdf" class="privacy-download">委任状</a>&emsp;&emsp;※ＰＤＦファイルをダウンロードし、印刷してご記入ください。<br/>
        &emsp;・ 本人の印鑑証明書（開示等の請求をする日前30日以内に作成されたもの）<br/>
        &emsp;・ 委任による代理人本人であることを確認するための書類&emsp;※上記、②a)～c)のいずれかの写し&emsp;<br/>
        <li>保有個人データの“利用目的の通知”又は“開示”の請求手数料として、1件につき、1,000円（消費税込み）を申し受けます。</li>
        お送りいただく1.の書類と共に郵便定額小為替（発行手数料は、請求者負担）を同封してください。<br/>
        万が一、同封されていなかった場合は、その旨ご連絡申し上げますが、所定の期間内にご郵送いただけない場合は、開示等の請求がなかったものとして対応させていただきます。
        <li>「個人情報開示等請求書」を受領後、必要書類一式（手数料含む）及びその記載内容に不備がないこと、また開示等の請求等をする者の本人又は代理人であることが確認できた時点で、対応を開始いたします。</li>
        原則として開示等の請求を受けてから10営業日以内に「個人情報開示等請求書」にご記入いただいた本人又は代理人の住所に郵送にて回答させていただきますが、開示等の請求の内容によっては、回答にお時間をいただく場合がありますのでご了承ください。
      </ol>
    </div>

    <div class="mt-32 p-8 bk-darkblue" id="disclosureRequest">
      <p class="privacy-title">プライバシーマーク(Pマーク)について</p>
    </div>
    <div class="privacy-topic-description">
      <table class="privacy-table">
        <tr>
          <th class="privacy-th">認定年月日</th>
          <td class="privacy-td">2008年5月12日</td>
        </tr>
        <tr>
          <th class="privacy-th">機関</th>
          <td class="privacy-td">一般財団法人&emsp;日本情報経済社会推進協会(JIPDEC)</td>
        </tr>
      </table>
      <div class="pmark clearfix">
        <a href="http://privacymark.jp/" target="_blank"><img src="@/assets/img/pmark.gif" border="0"></a>
      </div>
      <div style="font-size: 14px">
        「プライバシーマーク」は、個人情報の取扱いについて適切な保護措置を講じていると認められた民間企業に対して認定・付与しているものです。<br/>
        認定された企業は、事業活動に「プライバシーマーク」を使用することができます。<br/>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import TopicTitle from '@/components/title/TopicTitle.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    TopicTitle
  },
  methods: {
    ScrollWindow(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'center'
      })
    }
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'PRIVACY POLICY' }
      ]
    }
  }
}
</script>
<style>
.privacy-topic-description {
  padding: 20px 40px 20px 40px;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
.privacy-topic-description ol {
  padding: 0px 0px 0px 20px;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
.privacy-topic-description ul {
  padding: 0px 0px 0px 20px;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
.privacy-title {
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
}
.privacy-download {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  text-decoration:underline;
  text-decoration-color:#FF0000;
  color: #FF0000;
}
.privacy-th {
  width: 100px;
  padding: 10px 10px 10px 10px;
  border: 1px solid rgb(0, 0, 0);
  padding: 10px;
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
.privacy-td {
  width: 400px;
  padding: 15px;
  border-collapse: collapse;
  border: 1px solid rgb(0, 0, 0);
  font-size: 16px;
  font-weight: normal;
  color: #000000;
}
.privacy-table {
  width: 50%;
  margin-right: 20px;
  border-collapse: collapse;
  border: 1px solid rgb(0, 0, 0);
  letter-spacing: 1px;
  font-size: 16px;
  float: left;
}
.pmark {
  padding: 20px 10px 0px 10px;
}
</style>