<template>
  <div id="contents" class="bk-white">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'CAREER'" :jpTitle="'キャリア採用'" />
    <topic-title class="fs-28" :title="'選考プロセス'" />
    <recruit-process>
      <template v-slot:description1><p class="column-description">電話または電子メールにてご連絡ください。<br>採用担当から選考プロセスのご案内をさせていただきます。</p></template>
      <template v-slot:description2><p class="column-description">◆書類選考</p></template>
      <template v-slot:documents><p>・履歴書（写真貼付）<br>・職務経歴書<br>・技術経歴書（職務経歴書に併記も可）<br></p></template>
      <template v-slot:description3><p class="column-description">◆適性テスト<br>◆面接</p></template>
    </recruit-process>
    <topic-title class="fs-28" :title="'募集要項'" />
    <recruit-table
      :qualification="'学歴不問\n※ITエンジニア実務経験1年以上ある方優遇\n※未経験でも歓迎'"
      :salary="'210,300円～600,000円\n※上記はあくまでも未経験の最低保証額です。（年齢・社会人経験・能力・前職給与等を考慮の上、加給優遇）'" />
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import TopicTitle from '@/components/title/TopicTitle.vue'
import RecruitTable from '@/components/recruit/Table.vue'
import RecruitProcess from '@/components/recruit/Process.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    TopicTitle,
    RecruitTable,
    RecruitProcess
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'RECRUIT', path: '/recruit' },
        { name: 'CAREER' }
      ]
    }
  }
}
</script>