<template>
    <div class="recruit-table">
      <table>
        <tr>
          <th>【職種】</th>
          <td>システムエンジニア／プログラマ</td>
        </tr>
        <tr v-if="qualification">
          <th>【応募資格】</th>
          <td v-text="qualification" />
        </tr>
        <tr>
          <th>【勤務地】</th>
          <td>本社（東京都台東区）またはプロジェクト先（東京近郊）</td>
        </tr>
        <tr>
          <th>【勤務時間】</th>
          <td>９：００～１７：３０&emsp;※担当するプロジェクトにより指定する場合あり</td>
        </tr>
        <tr>
          <th>【給与】</th>
          <td v-text="salary"/>
        </tr>
        <tr>
          <th>【昇給】</th>
          <td>年1回&emsp;※実績・能力重視評価</td>
        </tr>
        <tr>
          <th>【賞与】</th>
          <td>年2回（7月、12月）&emsp;※実績・能力重視評価</td>
        </tr>
        <tr>
          <th>【各種手当】</th>
          <td>通勤手当／資格手当／超過勤務手当</td>
        </tr>
        <tr>
          <th>【休日】</th>
          <td>週休２日制（土曜日・日曜日）／祝日</td>
        </tr>
        <tr>
          <th>【休暇】</th>
          <td>年次有給／夏季／年末年始／慶弔／産前産後／育児・介護<br>
            ★「時間単位年休制度」を導入！（年間40時間まで使用可）
          </td>
        </tr>
        <tr>
          <th>【社会保険】</th>
          <td>健康保険／厚生年金保険／雇用保険／労災保険</td>
        </tr>
        <tr>
          <th>【支援制度】</th>
          <td>
            ・資格支援制度：&emsp;IT関連技術の資格取得者には、受験料及び資格手当を支給！<br/>
            ・書籍購入支援制度：&emsp;スキルの向上に繋がる書籍購入を支援！<br/>
            ・研修・セミナー受講支援制度：&emsp;事業展開上優位とされる研修・セミナー受講希望者に受講支援！
          </td>
        </tr>
        <tr>
          <th>【その他】</th>
          <td>
            ・退職金制度<br>
            ・定期健康診断（年１回）<br>
            ・食事会・レクリエーション（任意参加）※費用は会社負担<br>
            ・健康保険組合の各地保養施設・スポーツ施設利用可<br>
          </td>
        </tr>
      </table>
  </div>
</template>
<script>
export default {
  props: {
    qualification: {
      type: String
    },
    salary: {
      type: String,
      require: true
    }
  }
}
</script>
<style>
.recruit-table { padding: 20px; text-align: left; }
.recruit-table table { border-collapse: separate; border-spacing: 30px 12px; }
.recruit-table th { width: 5%; font-size: 15px; border: none; font-weight: normal; vertical-align: top; text-align: justify; text-align-last: justify; text-justify: inter-ideograph; }
.recruit-table td { width: 50%; font-size: 15px; border: none; white-space: pre-line }
</style>