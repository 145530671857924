<template>
  <div id="infoYL">
    <div class="clearfix">
      <h1 class="info-box">INFO</h1>
      <h2 class="info-title">{{ title }}</h2>
    </div>
    <hr class="info-border">
    <div class="clearfix mt-10">
      <div class="info-date">{{ date }}</div>
      <slot name="text" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    },
    date: {
      type: String,
      require: true
    }
  }
}
</script>
<style>
#infoYL h1 {
  background: #BF9000;
}
#infoYL hr {
  background-color: #BF9000;
  color: #BF9000;
}
.clearfix::after {
  content: "";
  display: block;
  clear: both;
}
.info-box {
  font-family: "BIZ UDGothic";
  width: 110px;
  margin-left: 50px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  float: left;
}
.info-border {
  margin-left: 180px;
  margin-right: 50px;
  height: 1px;
  border: none;
}
.info-title {
  margin-left: 30px;
  padding-top: 3px;
  font-size: 16px;
  font-weight: bold;
  float: left;
}
.info-date {
  font-family: "BIZ UDGothic";
  width: 110px;
  margin-left: 50px;
  font-size: 14px;
  text-align: center;
  color: #808080;
  float: left;
}
.info-text {
  white-space: pre-wrap;
  width: 960px;
  margin-left: 30px;
  margin-bottom: 32px;
  padding-top: 3px;
  font-size: 14px;
  float: left;
}
</style>