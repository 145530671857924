<template>
  <div class="breadcrumb-area">
    <span class="breadcrumb-item">
      <span v-for="v in breadcrumb" :key="v.name">
        <span v-if="v.path">
          <router-link :to="v.path">
            <span class="link">{{ v.name }}</span>
          </router-link>
          <span class="space"> > </span>
        </span>
        <span v-else>{{ v.name }}</span>
        </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumb: {
      type: Object
    }
  }
}
</script>

<style>
.breadcrumb-area {
  padding: 10px !important;
  text-align: right;
  font-size: 16px;
}
.breadcrumb-item a { font-size: 16px; color: #002060; }
.breadcrumb-item a:hover { color: #64d4ff; }
</style>
