<template>
  <div id="contents" class="bk-white pb-32">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'NEWS'" :jpTitle="'お知らせ'" />
    <news-recruit :title="'キャリア採用強化中！'" :date="'2023.01.26'">
        <template v-slot:text>
          <div class="info-text">
            現在、弊社では事業拡大に向けてキャリア採用を積極的に強化しております。<br>
            ＩＴエンジニアの実務経験が１年以上ある方、ＩＴエンジニアになりたいという熱い思いがある方を募集中です。<br>
            詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
            たくさんのご応募心よりお待ちしております。
          </div>
        </template>
    </news-recruit>
    <news-info :title="'年末年始に伴う休業期間のお知らせ'" :date="'2022.12.6'">
        <template v-slot:text>
          <div class="info-text">
            誠に勝手ながら、下記とおり休業させていただきます。<br>
            皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
            本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
            ［休業期間］&emsp;2022年12月30日～2023年1月3日<br>
            ［通常営業］&emsp;2023年1月4日～
          </div>
        </template>
      </news-info>
    <news-recruit :title="'2023年度 新卒採用を開始しました！'" :date="'2022.02.07'">
        <template v-slot:text>
          <div class="info-text">
            2023年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
            詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
            弊社では、新型コロナウイルスの感染拡大を受け、皆様の安全確保を最優先に考え、オンラインでのWEB会社説明会を実施いたします。<br>
            今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
          </div>
        </template>
      </news-recruit>
    <news-info class="mt-40" :title="'年末年始に伴う休業期間のお知らせ'" :date="'2021.12.10'">
      <template v-slot:text>
        <div class="info-text">
          誠に勝手ながら、下記とおり休業させていただきます。<br>
          皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
          本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
          ［休業期間］&emsp;2021年12月30日～2022年1月3日<br>
          ［通常営業］&emsp;2022年1月4日～
        </div>
      </template>
    </news-info>
    <news-recruit :title="'2022年度 新卒採用を開始しました！'" :date="'2021.02.16'">
      <template v-slot:text>
        <div class="info-text">
          2022年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
          詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
          弊社では、新型コロナウイルスの感染拡大を受け、皆様の安全確保を最優先に考え、オンラインでのWEB会社説明会を実施いたします。<br>
          今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
        </div>
      </template>
    </news-recruit>
    <news-info :title="'テレワーク対応開始のお知らせ'" :date="'2021.01.25'">
      <template v-slot:text>
        <div class="info-text">
          新型コロナウイルス感染拡大リスクの最小化、および関係者の皆様や弊社従業員の安全確保を目的に、テレワーク（在宅勤務）対応を開始いたしました。<br>
          お客様、関係各位におかれましては、ご不便お掛けいたしますが、何卒ご理解を賜りますようお願い申し上げます。
        </div>
      </template>
    </news-info>
    <news-info :title="'年末年始に伴う休業期間のお知らせ'" :date="'2020.12.10'">
      <template v-slot:text>
        <div class="info-text">
          誠に勝手ながら、下記とおり休業させていただきます。<br>
          皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
          本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
          ［休業期間］&emsp;2020年12月30日～2021年1月3日<br>
          ［通常営業］&emsp;2021年1月4日～
        </div>
      </template>
    </news-info>
    <news-info :title="'年賀状廃止のお知らせ'" :date="'2020.10.28'">
      <template v-slot:text>
        <div class="info-text">
          弊社では2021年より、すべてのお取引先様に対し、年賀状によるご挨拶を控えさせていただくことといたしました。<br>
          近年の世情も鑑みつつ、数年来の検討を続けておりましたが、本年を一つの区切りとして決定をしました。<br>
          誠に勝手ではございますが、何卒ご理解を賜りますとともに、今後とも変わらぬご支援ご愛顧を賜りますようお願い申し上げます。
        </div>
      </template>
    </news-info>
    <news-recruit :title="'2021年度 新卒採用を開始しました！'" :date="'2020.02.27'">
      <template v-slot:text>
        <div class="info-text">
          2021年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
          詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
          今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
        </div>
      </template>
    </news-recruit>
    <news-info :title="'年末年始に伴う休業期間のお知らせ'" :date="'2019.12.09'">
      <template v-slot:text>
        <div class="info-text">
          誠に勝手ながら、下記とおり休業させていただきます。<br>
          皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
          本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
          ［休業期間］&emsp;2019年12月30日～2020年1月3日<br>
          ［通常営業］&emsp;2020年1月6日～
        </div>
      </template>
    </news-info>
    <news-info :title="'創立30周年を迎えました！'" :date="'2019.09.01'">
      <template v-slot:text>
        <div class="info-text">
          ファーストシステム株式会社は、おかげさまで創立30周年を迎ることができました。<br>
          これもひとえにお客様、全ての関係者の皆様のご支援、ご愛顧の賜物と心から感謝いたしております。<br><br>
          今後も皆様のご期待ご要望に沿えますよう、これを機に社員一丸となってより一層邁進して参ります。<br><br>
          今後とも変わらぬご愛顧とご支援を賜りますよう、どうぞよろしくお願い申し上げます。
        </div>
      </template>
    </news-info>
    <news-recruit :title="'2020年度 新卒採用を開始しました！'" :date="'2019.02.07'">
      <template v-slot:text>
        <div class="info-text">
          2020年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
          詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
          今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
        </div>
      </template>
    </news-recruit>
    <news-info :title="'年末年始に伴う休業期間のお知らせ'" :date="'2018.12.07'">
      <template v-slot:text>
        <div class="info-text">
          誠に勝手ながら、下記とおり休業させていただきます。<br>
          皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
          本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
          ［休業期間］&emsp;2018年12月30日～2019年1月3日<br>
          ［通常営業］&emsp;2019年1月4日～
        </div>
      </template>
    </news-info>
    <news-recruit :title="'2019年度 新卒採用を開始しました！'" :date="'2018.02.26'">
      <template v-slot:text>
        <div class="info-text">
          2019年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
          詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
          今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
        </div>
      </template>
    </news-recruit>
    <news-info :title="'事務所移転のお知らせ'" :date="'2018.01.01'">
      <template v-slot:text>
        <div class="info-text">
          この度、弊社は下記のとおり事務所を移転いたしました。<br/>
          これを機に、社員一同心を新たに皆様のご期待にお応えできますよう尽力して参る所存でございますので、今後とも一層のお引立てを賜りますようよろしくお願い申し上げます。<br><br>
          ［移転先］&emsp;&emsp;&emsp;〒111-0053&emsp;東京都台東区浅草橋5-2-14&emsp;浅草橋ハイツ3階&emsp;&emsp;TEL：03-5809-2214&emsp;FAX：03-5809-2284<br>
          ［業務開始日］&emsp;2018年1月1日
        </div>
      </template>
    </news-info>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import NewsInfo from '@/components/news/Info.vue'
import NewsRecruit from '@/components/news/Recruit.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    NewsInfo,
    NewsRecruit
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'NEWS' }
      ]
    }
  }
}
</script>