<template>
  <div class="mt-32 p-8 bk-lightblue">
    <p class="topic">{{ title }}</p>
    <p class="sub-topic">{{ jpTitle }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      require: true
    },
    jpTitle: {
      type: String,
      require: true
    }
  }
}
</script>
<style>
.topic {
  font-family: "BIZ UDGothic";
  margin: 0px 20px;
  font-size: 48px;
  border-bottom: solid #ffffff 1px;
  font-weight: bold;
  color: #ffffff;
  width: 50%;
}
.sub-topic {
  margin: 0px 20px;
  font-size: 16px;
  font-weight: normal;
  color: #ffffff;
  width: 50%;
}
</style>