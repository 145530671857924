<template>
  <div id="contents" class="bk-white">
    <div class="mb-50 mt-20" style="width: 100%;height: 550px;position: relative">
      <div style="position: absolute;width: 100%;height: 100%;background-color: #000000;z-index: -5;opacity: 0.5;"/>
      <img class="image" src="@/assets/img/topbackground1.png"/>
      <img class="image" src="@/assets/img/topbackground2.jpg"/>
      <img class="image" src="@/assets/img/topbackground3.jpg"/>
      <span class="bk-darkblue" style="position: absolute;left: 0px; width: 400px;height: 100%;opacity: 0.8;" />
      <p class="js-fadeIn-text">すべては、ベストソリューションのため<br>FIRSTSYSTEMは、ITの可能性に挑戦し続けます。</p>
      <h2 class="js-fadeInUp-text">Everything is for<br>the best solution</h2>
    </div>
    <div class="bk-white" style="overflow: hidden">
      <topic-title class="mb-32 fs-40" :title="'NEWS'" />
      <news-recruit :title="'2025年度 新卒採用を開始しました！'" :date="'2024.03.13'">
        <template v-slot:text>
          <div class="info-text">
            2025年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
            詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
            オンラインでのWEB会社説明会を実施いたしますので、全国どこからでもお気軽にご参加ください！<br>
            今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。<br>

          </div>
        </template>
      </news-recruit>
      <news-info :title="'年末年始に伴う休業期間のお知らせ'" :date="'2023.12.22'">
        <template v-slot:text>
          <div class="info-text">
            誠に勝手ながら、下記とおり休業させていただきます。<br>
            皆様にはご迷惑をお掛けいたしますが、何卒ご容赦くださいますようお願い申し上げます。<br>
            本年中のご愛顧に心から御礼申し上げますとともに、来年も引き続きお力添えをいただきますようお願いいたします。<br><br>
            ［休業期間］&emsp;2023年12月30日～2024年1月3日<br>
            ［通常営業］&emsp;2024年1月4日～<br>
          </div>
        </template>
      </news-info>
      <news-info :title="'代表取締役交代のお知らせ'" :date="'2023.09.01'">
        <template v-slot:text>
          <div class="info-text">
            このたび、2023年（令和５年）9月1日をもちまして、宇山一夫が代表取締役を退任し、取締役会長に就任するとともに、西村 健が代表取締役に<br>
            就任いたしましたことをご報告申し上げます。<br><br>
            新体制の下、社員一丸となり、新しいFIRSTSYSTEMとして更なる成長を目指して邁進していきますので、今後とも倍旧のご支援を賜りますよう、<br>
            よろしくお願い申し上げます。<br><br><br>
            <div style="font-family:BIZ UDPMincho;">
              <span style="text-decoration: underline;">退任のご挨拶</span>
              <div class="mt-10 mrl-32 mb-32" 
                style="border: dotted 1px;
                       padding: 16px;
                       width: 70%;">
                謹啓&emsp;時下ますますご清祥のこととお慶び申し上げます<br>
                <div style="text-align: right;">さて&emsp;私儀</div>
                このたび&emsp;代表取締役を退任し取締役会長に就任いたしました<br>
                在任中は&emsp;多年にわたり格別のご高配ご懇情を賜り衷心より御礼申し上げます<br>
                なお&emsp;後任には西村健が代表取締役に就任いたしましたので&emsp;何卒ご高承のうえご指導ご鞭撻を賜りますようお願<br>
                い申し上げます<br>
                これまで賜りましたご厚情に深謝しホームページ公開をもってご挨拶申し上げます<br>
                <div style="text-align: right;">謹言</div><br>
                &emsp;&emsp;令和5年9月吉日<br>
                <div style="text-align: right;">
                  ファーストシステム株式会社<br>
                  代表取締役会長&emsp;宇山&emsp;一夫
                </div>
              </div>
              <span style="text-decoration: underline;">就任のご挨拶</span>
              <div class="mt-10 mrl-32" 
                style="border: dotted 1px;
                       padding: 16px;
                       width: 70%;">
                謹啓&emsp;初秋の候&emsp;いよいよご清栄のこととお慶び申し上げます<br>
                <div style="text-align: right;">さて&emsp;私儀</div>
                このたび&emsp;宇山一夫の後任として代表取締役に就任いたしました<br>
                つきましては&emsp;まだまだ未熟ではございますが&emsp;一意専心社業の発展のため微力を尽くしてまいる所存でございま<br>
                すので何卒今後共一層のご指導ご支援を賜りますようお願い申し上げます<br>
                さっそく拝眉のうえご挨拶申し上げるべきところ&emsp;まずは略儀ながらホームページ公開をもって新任のご挨拶を申<br>
                し上げます<br>
                <div style="text-align: right;">謹言</div><br>
                &emsp;&emsp;令和5年9月吉日<br>
                <div style="text-align: right;">
                ファーストシステム株式会社<br>
                代表取締役&emsp;西村&emsp;健
                </div>
              </div>
            </div>
          </div>
        </template>
      </news-info>
      <news-info :title="'ホームページリニューアルのお知らせ'" :date="'2023.02.20'">
        <template v-slot:text>
          <div class="info-text">
            日頃より、ファーストシステム株式会社のホームページをご覧いただき、誠にありがとうございます。<br>
            この度、ホームページを全面リニューアルいたしましたので、お知らせします。<br><br>
            今回のリニューアルでは、デザインやページ構成を見直し、新コンテンツの追加も実施しました。<br>
            今後も内容の充実や改善に努め、分かりやすく使いやすいホームページを目指してまいりますので、引き続きどうぞよろしくお願いいたします。
          </div>
        </template>
      </news-info>
      <news-recruit :title="'2024年度 新卒採用を開始しました！'" :date="'2023.02.13'">
        <template v-slot:text>
          <div class="info-text">
            2024年4月入社予定の学生を対象とした新卒採用を開始いたしました。<br>
            詳細な募集内容に関しては、弊社RECRUITサイトをご覧ください。<br><br>
            弊社では、新型コロナウイルスの感染拡大を受け、皆様の安全確保を最優先に考え、オンラインでのWEB会社説明会を実施いたします。<br>
            今年度も多くの学生の皆様にお会いできますことを、社員一同、楽しみにしております。
          </div>
        </template>
      </news-recruit>
      <div class="ml-20 mb-50">
        <view-more :path="'/news'" />
      </div>
    </div> 
  </div>
</template>

<script>
import TopicTitle from '@/components/title/TopicTitle.vue'
import NewsInfo from '@/components/news/Info.vue'
import NewsRecruit from '@/components/news/Recruit.vue'
import ViewMore from '@/components/common/ViewMore.vue'

export default {
  components: {
    TopicTitle,
    NewsInfo,
    NewsRecruit,
    ViewMore
  },
  methods: {
    showfadeInUp() {
      var element = document.getElementsByClassName('js-fadeInUp-text')
      for(var i = 0; i < element.length; i++) { 
        if (element[i].classList.contains('is-show')) {
          element[i].classList.remove('is-show')
        } else {
          element[i].classList.add('is-show')
        }
      }
    },
    showfadeIn() {
      var element = document.getElementsByClassName('js-fadeIn-text')
      for(var i = 0; i < element.length; i++) { 
        if (element[i].classList.contains('is-show')) {
          element[i].classList.remove('is-show')
        } else {
          element[i].classList.add('is-show')
        }
      }
    },
  },
  mounted() {
    setTimeout(() => { this.showfadeInUp() }, 1500)
    setTimeout(() => { this.showfadeIn() }, 2000)
  },
  destroyed() {
    this.showfadeInUp()
    this.showfadeIn()
  }
}
</script>
<style>
.image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: change-img-anim 18s infinite;
  z-index: 0;
}
.image:nth-of-type(1) { animation-delay: 0s; }
.image:nth-of-type(2) { animation-delay: 6s; }
.image:nth-of-type(3) { animation-delay: 12s; }

@keyframes change-img-anim {
  0%{ opacity: 0;}
  20%{ opacity: 1;}
  33%{ opacity: 1;}
  63%{ opacity: 0;}
  100%{ opacity: 0;}
}

.js-fadeInUp-text {
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: all 2s;
  position: absolute;
  left: 30px;
  bottom: 20px;
  font-size: 80px;
  line-height: 1.2em;
  color: #ffffff;
  font-family: 'BIZ UDGothic'
}
.js-fadeInUp-text.is-show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}
.js-fadeIn-text {
  opacity: 0;
  position: absolute;
  right: 30px;
  top: 20px;
  font-size: 24px;
  color: #ffffff;
  text-align: right;
}
.js-fadeIn-text.is-show { animation: fadeIn 3s forwards; }
</style>