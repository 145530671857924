<template>
  <div>
    <div class="title-img" :style="[{ 'background-image': 'url(' + require(`@/assets/img/${image}`) + ')', }]">
      <slot name="title" />
    </div>
    <div class="img-description bk-gray">
      <slot name="description1" />
      <slot name="description2" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      require: true
    }
  }
}
</script>
<style>
.title-img {
  margin-top: 20px;
  display: flex;
  background-size: 100%;
  background-position: center;
  height: 295px;
}
.title-img h1 {
  font-family: "BIZ UDGothic";
  margin-top: 190px;
  font-size: 50px;
  color: #ffffff;
  width: 100%;
  height: 25%;
  padding: 16px 0px;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  background-color:rgba(0, 32, 128, 0.5);
}
.title-img h2 {
  margin-top: 190px;
  margin-bottom: 0px;
  font-size: 24px;
  color: #BF9000;
  width: 100%;
  height: 25%;
  padding: 16px 0px;
  line-height: 320%;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  background-color:rgba(0, 32, 128, 0.5);
}
.title-img h3 {
  margin-top: 190px;
  margin-bottom: 0px;
  font-size: 24px;
  color: #ffffff;
  width: 100%;
  height: 25%;
  padding: 16px 0px;
  line-height: 320%;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  background-color:rgba(0, 32, 128, 0.5);
}
.img-description1 {
  color: #BF9000;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  text-align: center;
}
.img-description2 {
  white-space: pre-wrap;
  color: #002060;
  font-size: 16px;
  padding: 20px;
  font-weight: bold;
  text-align: center;
}
</style>