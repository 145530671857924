<script>
import { Doughnut } from 'vue-chartjs'
export default {
  extends: Doughnut,
  props: {
    inputTitle: { 
      type: String,
      require: true
    },
    inputLabel: { 
      type: Array,
      require: true
    },
    inputData: {
      type: Array,
      require: true
    },
    inputBackgroundColor: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      datas: {
        labels: this.inputLabel,
        datasets: [{
          data: this.inputData,
          backgroundColor: this.inputBackgroundColor
        }],
      },
      options: {
        responsive: true,
        title: {
          display: true,
          fontSize: 20,
          text: this.inputTitle
        },
        legend: {
          display: true
        },
        tooltips: {
          xPadding: 12,
          yPadding: 10,
          bodyFontSize: 20,
          callbacks: {
            label: function(tooltipItems, datas) {
              return datas.labels[tooltipItems.index] + " : " +
                  datas.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + "%";
            }
          }
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.datas, this.options)
  }
}
</script>