<template>
  <div id="contents" class="bk-white pb-20">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'DATA'" :jpTitle="'データで見る！FIRSTSYSTEM'" />
    <ul class="data-list">
      <li>
        <pie-chart :inputTitle="'文系 or 理系'"
          :inputLabel="['理系', 'その他', '文系']"
          :inputData="[31, 19, 50]"
          :inputBackgroundColor="['#ffc000', '#a5a5a5', '#64d4ff']" />
      </li>
      <li>
        <pie-chart :inputTitle="'新卒 or キャリア'"
          :inputLabel="['新卒', 'キャリア']"
          :inputData="[69, 31]"
          :inputBackgroundColor="['#64d4ff', '#002060']" />
      </li>
    </ul>
    <ul class="data-list">
      <li>
        <pie-chart :inputTitle="'男性 or 女性'"
          :inputLabel="['男性', '女性']"
          :inputData="[87, 13]"
          :inputBackgroundColor="['#64d4ff', '#ff0066']" />
      </li>
      <li>
        <pie-chart :inputTitle="'メガネコンタクト or 裸眼'"
          :inputLabel="['メガネ', 'コンタクト', '裸眼']"
          :inputData="[37, 19, 44]"
          :inputBackgroundColor="['#64d4ff', '#002060', '#ffc000']" />
      </li>
    </ul>
    <ul class="data-list">
      <li>
        <pie-chart :inputTitle="'きょうだい型'"
          :inputLabel="['長子', '末っ子', '一人っ子']"
          :inputData="[69, 25, 6]"
          :inputBackgroundColor="['#64d4ff', '#ffc000', '#002060']" />
      </li>
      <li>
        <pie-chart :inputTitle="'血液型'"
        :inputLabel="['A型', 'O型', 'AB型']"
          :inputData="[50, 31, 19]"
          :inputBackgroundColor="['#64d4ff', '#002060', '#ffc000']" />
      </li>
    </ul>
    <ul class="data-list">
      <li>
        <bar-chart :inputTitle="'年齢'"
          :inputLabel="['50代', '40代', '30代', '20代']"
          :inputData="[6, 19, 44, 31]"
          :inputBackgroundColor="['#ff0066', '#002060', '#64d4ff', '#ffc000']" />
      </li>
      <li>
        <bar-chart :inputTitle="'身長'"
          :inputLabel="['180cm', '170cm', '160cm', '150cm', '140cm']"
          :inputData="[13, 25, 37, 19, 6]"
          :inputBackgroundColor="['#002060', '#a5a5a5', '#64d4ff', '#ffc000', '#ff0066']" />
      </li>
    </ul>
    <ul class="contact-info">
      <li><img width="90%" src="@/assets/img/graph-birthplace.png"/></li>
    </ul>
    <div class="tally-time">※2022年12月集計</div>
  </div>
</template>

<script>
import PieChart from '@/components/chart/PieChart.vue'
import BarChart from '@/components/chart/BarChart.vue'
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'

export default {
  components: {
    PieChart,
    BarChart,
    Breadcrumb,
    PageTitle
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'RECRUIT', path: '/recruit' },
        { name: 'DATA' }
      ]
    }
  }
}
</script>

<style>
.data-list {
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
}
.data-list li {
  border: #808080 solid 3px;
  text-align: center;
  width: 40%;
  margin: 20px 60px;
  border-radius: 32px;
  padding: 30px;
}
.tally-time {
  margin-right: 20px;
  text-align: right;
  font-size: 16px;
  color: #808080;

}
</style>