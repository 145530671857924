<template>
  <div id="contents" class="bk-white pb-20">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'CONTACT'" :jpTitle="'お問合せ'" />
    <ul class="contact-info">
      <li><img width="80%" src="@/assets/img/tel-info.png"/></li>
      <li><img width="80%" src="@/assets/img/mail-info.png"/></li>
    </ul>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle 
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'CONTACT' }
      ]
    }
  }
}
</script>

<style>
.contact-info {
  display: flex;
  list-style: none;
  flex-direction: row;
  justify-content: space-around;
  margin: 20px;
  text-align: center;
}
</style>