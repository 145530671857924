<template>
  <div id="contents" class="bk-white">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <page-title :title="'COMPANY'" :jpTitle="'会社概要'" />
    <title-image :key="company" :image="'company-back.png'">
      <template v-slot:title><h1>FIRSTSYSTEM IZM</h1></template>
      <template v-slot:description1>
        <p class="img-description1">情報システムテクノロジーの可能性へ無限にチャレンジし、付加価値の高い情報サービスを発信し続けます。<br></p>
      </template>
      <template v-slot:description2>
        <p class="img-description2">
          FIRSTSYSTEMは、先進的なIT技術を吸収しつづけ、ベストに妥協することなく、<br>
          お客様の事業発展と持続的な成長をサポートし、社会に貢献する情報サービス会社を目指していきます。<br>
        </p>
      </template>
    </title-image>
    <div class="company-table">
      <table>
        <tr>
          <th><div class="th-in">社名</div></th>
          <td>ファーストシステム株式会社<br>FIRSTSYSTEM CO., LTD</td>
        </tr>
        <tr>
          <th><div class="th-in">代表者</div></th>
          <td>代表取締役&emsp;西村&emsp;健</td>
        </tr>
        <tr>
          <th><div class="th-in">所在地</div></th>
          <td>〒111-0053<br>東京都台東区浅草橋 5-2-14&emsp;浅草橋ハイツ 3階</td>
        </tr>
        <tr>
          <th><div class="th-in">Ｔ Ｅ Ｌ</div></th>
          <td>03-5809-2214</td>
        </tr>
        <tr>
          <th><div class="th-in">Ｆ Ａ Ｘ</div></th>
          <td>03-5809-2284</td>
        </tr>
        <tr>
          <th><div class="th-in">設立</div></th>
          <td>平成元年8月</td>
        </tr>
        <tr>
          <th><div class="th-in">資本金</div></th>
          <td>1,000万円</td>
        </tr>
        <tr>
          <th><div class="th-in">事業内容</div></th>
          <td>
            ・各種業務システム企画・設計・開発・保守<br>
            ・クラウドサービス<br>
            ・パッケージソフトウェア販売 および システムエンジアリング<br>
            ・アプリケーション開発（Android・UNIX・Linux・Windows）<br>
          </td>
        </tr>
        <tr>
          <th><div class="th-in">主要取引先<br>（50音順）</div></th>
          <td>
            株式会社BBSアウトソーシングサービス<br>
            株式会社セゾン情報システムズ<br>
            株式会社トラストシステム・サービス<br>
            ハイブリィド株式会社<br>
            ビリングシステム株式会社<br>
            富士電機ITセンター株式会社<br>
          </td>
        </tr>
        <tr>
          <th><div class="th-in">登録・認証</div></th>
          <td>
            <p class="privacy-mark">
              平成20年5月&emsp;プライバシーマーク認定&emsp;第10822891号
              <a class="privacy-mark" href="http://privacymark.jp/" target="_blank"><img src="@/assets/img/pmark.gif"/></a>
            </p>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import TitleImage from '@/components/title/TitleImage.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    TitleImage
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'COMPANY' }
      ]
    }
  },
}
</script>

<style>
.privacy-mark { vertical-align: middle; }
.company-table { padding: 80px; }
.company-table th { width: 40%; font-size: 16px; padding: 20px; font-weight: normal; text-align: justify; text-align-last: justify; text-justify: inter-ideograph; }
.company-table .th-in { width: 40%; margin: 0px auto; }
.company-table td { width: 60%; font-size: 15px; padding: 20px; }
.company-table tr { border-bottom: 1px solid rgb(175, 175, 175); }
</style>