<script>
import { HorizontalBar } from 'vue-chartjs'
export default {
  extends: HorizontalBar,
  props: {
    inputTitle: { 
      type: String,
      require: true
    },
    inputLabel: { 
      type: Array,
      require: true
    },
    inputData: {
      type: Array,
      require: true
    },
    inputBackgroundColor: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      datas: {
        labels: this.inputLabel,
        datasets: [{
          data: this.inputData,
          backgroundColor: this.inputBackgroundColor
        }]
      },
      options: {
        responsive: true,
        title: {
          display: true,
          fontSize: 20,
          text: this.inputTitle
        },
        legend: {
          display: false
        },
        tooltips: {
          xPadding: 12,
          yPadding: 10,
          bodyFontSize: 20,
          callbacks: {
            label: function(tooltipItems, datas) {
              return datas.labels[tooltipItems.index] + " : " +
                  datas.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + "%";
            }
          }
        },
        scales: {
          ticks: {
            min: 0,
            max: 50,
            stepSize: 10
          },
          xAxes: [{
            display: false,
            gridLines: {
              display: false
            }
          }],
          yAxes: [{
            display: true,
            categoryPercentage: 1.1,
            ticks: {
              fontSize: 20
            },
            gridLines: {
              drawBorder: false
            }
          }]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.datas, this.options)
  }
}
</script>