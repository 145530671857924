<template>
  <button id="return-top" @click="ScrollTop">△</button>
</template>
<script>
export default {
  methods: {
    ScrollTop() {
      document.getElementById('headers').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    },
    display() {
      if(window.scrollY >= 150) {
        document.getElementById('return-top').style.visibility = 'visible'
      } else {
         document.getElementById('return-top').style.visibility = 'hidden'
      }
    }
  },
  mounted: function() {
    window.addEventListener('scroll', this.display, false)
  },
  destroyed: function() {
    window.removeEventListener('scroll', this.display, false)
  }
}
</script>
<style>
#return-top {
  cursor: pointer;
  z-index: 999;
  background-color: #002060;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 60px;
  height: 60px;
  border: solid thin;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-content: center;
  visibility: hidden;
}
</style>