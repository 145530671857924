<template>
  <div id="app">
    <return-top />
    <Header id="headers" />
    <router-view />
    <Footer id="footers" />
  </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue'
import Header from '@/components/common/Header.vue'
import ReturnTop from '@/components/common/ReturnTop.vue'

export default {
  components: {
    Header,
    Footer,
    ReturnTop
  }
}
</script>

<style>
@import "~@/assets/scss/app.scss";
</style>
