<template>
  <div id="contents" class="bk-white">
    <breadcrumb :breadcrumb="breadcrumbs" />
    <!--<div style="position: relative;">-->
    <page-title :title="'NEW GRADUATES'" :jpTitle="'新卒採用'" />
    <topic-title class="fs-28" :title="'選考プロセス'" />
    <!--<div align="center" style="position:absolute; left:980px; top:60px;">
      <a href="https://job.rikunabi.com/2024/company/r811552085/" target="new">
      <img src="https://job.rikunabi.com/2024/static/common/contents/logos/rikunabi/image/rn_logo_b.gif" width="200" height="40" border="0">
      </a><br>
    </div>
    </div>-->
    <recruit-process>
      <template v-slot:description1><p class="column-description">電話または電子メールにてご連絡ください。<br>採用担当から会社説明会の日程調整のご案内をさせていただきます。</p>
        <br>
        <div align="center">
          エントリーはコチラ▼<br>
          <a href="https://job.rikunabi.com/2025/company/r811552085/" target="new">
          <img src="https://job.rikunabi.com/2025/static/common/contents/logos/rikunabi/image/rn_logo_s.gif" width="200" height="40" border="0">
          </a><br><br>
          <a href="https://job.rikunabi.com/2025/company/r811552085/" target="new"> リクナビの弊社画面へ </a>
        </div> 
      </template>
      <template v-slot:description2><p class="column-description">◆適性テスト<br>◆作文<br>◆書類選考</p></template>
      <template v-slot:documents><p>・履歴書（写真貼付）<br>・卒業見込証明書<br>・成績証明書<br></p></template>
      <template v-slot:description3><p class="column-description">◆面接</p></template>
    </recruit-process>
    <topic-title class="fs-28" :title="'募集要項'" />
    <recruit-table :salary="'大学・大学院卒：230,300円（BYOD手当含む）\n高専・専門・短大卒：210,300円（BYOD手当含む）'"/>

  </div>
</template>

<script>
import Breadcrumb from '@/components/common/Breadcrumb.vue'
import PageTitle from '@/components/title/PageTitle.vue'
import TopicTitle from '@/components/title/TopicTitle.vue'
import RecruitTable from '@/components/recruit/Table.vue'
import RecruitProcess from '@/components/recruit/Process.vue'
export default {
  components: {
    Breadcrumb,
    PageTitle,
    TopicTitle,
    RecruitTable,
    RecruitProcess
  },
  data() {
    return {
      breadcrumbs: [
        { name: 'TOP', path: '/' },
        { name: 'RECRUIT', path: '/recruit' },
        { name: 'NEW GRADUATES' }
      ]
    }
  }
}
</script>