<template>
  <div>
    <router-link :to="path" class="view-more">VIEW MORE >>></router-link>
  </div>
</template>
<script>
export default {
  props: {
    path: {
      type: String
    }
  }
}
</script>
<style>
.view-more { font-size: 16px; font-weight: normal; color: #3399FF; }
.view-more a :hover { color: #EAF3FA; }
</style>
