<template>
  <div id="splash">
    <div id="loader">
      <img id="splash-image" src="@/assets/img/firstsystem.jpg" />
    </div>
    <Top v-if="start" />
  </div>
</template>

<script>
import Top from '@/pages/Top.vue'
export default {
  props: {
    start: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Top
  },
  mounted() {
    document.getElementById('headers').style.visibility = 'hidden'
    document.getElementById('footers').style.visibility = 'hidden'
    setTimeout(() => {
      document.getElementById('splash-image').classList.add('fadeout')
    }, 1000)
    setTimeout(() => {
      document.getElementById('loader').classList.add('slideout')
      document.getElementById('headers').style.visibility = 'visible'
      document.getElementById('footers').style.visibility = 'visible'
      this.start = true
    }, 2000)
  }
}
</script>
<style>
#splash { animation: fadeIn 0.3s forwards; }
#loader {
  z-index: 99;
  width: 100%;
  height: 100%;
  margin: auto;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
#splash-image { width: 200px; margin: auto; }
.fadeout { animation: fadeOut 1s forwards; }
.slideout { animation: slideOut 4s cubic-bezier(0.25, 1, 0.5, 1) 1 forwards; }
</style>