<template>
  <div>
    <p class="title">{{ title }}</p>
    <p class="jptitle">{{ jpTitle }}</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
        type: String
    },
    jpTitle: {
        type: String
    }
  }
}
</script>
<style>
.title {
  font-family: "BIZ UDGothic";
  margin: 0px 20px;
  font-size: 48px;
  border-bottom: solid #000000 1px;
  font-weight: bold;
  color: #002060;
  width: 50%;
}
.jptitle {
  margin: 0px 20px;
  font-size: 16px;
  font-weight: normal;
  color: #7F7F7F;
  width: 50%;
}
</style>